import Login from './screens/login';
import Iframe from './screens/iframe';
import SelectPage from './screens/selectPage';
import ReactLoading from 'react-loading';
import useFirebase from './hooks/useFirebase';
import './App.css';
import { useState } from 'react';

function App() {
	const {
		authenticateWithEmailAndPassword,
		user,
		logout,
    availableUrls,
    loading
	} = useFirebase();
  const [chosenUrl, setChosenUrl] = useState<string | null>(null);

	const GetRoute = () => {
    if (loading) return <div style={{ width: "100%", height: "100%", backgroundColor: "#6400AA" }}>
        <div className="iframe-loading-container">
          <ReactLoading type="spin" color="#FFF" width="200px" height="200px" />
        </div>
    </div>;
    if (chosenUrl || availableUrls?.length ===1) return <Iframe
      setChosenUrl={setChosenUrl}
      availableUrls={availableUrls}
      logout={() => {
        logout();
        setChosenUrl(null);
      }}
      user={user}
      url={chosenUrl || availableUrls[0].url}
    />;
    if (availableUrls.length) return <SelectPage setChosenUrl={setChosenUrl} availableUrls={availableUrls} />;
	 	return <Login authenticateWithEmailAndPassword={authenticateWithEmailAndPassword} />;
	}

  return (
    <div className="App">
			<GetRoute />
    </div>
  );
}

export default App;
