import { useState } from 'react';
import ReactLoading from 'react-loading';
import './index.css';

function Iframe({ logout, user, url, setChosenUrl, availableUrls }: any) {
  const [loading, setLoading] = useState<boolean>(true);
  if (!url) {
    return (
      <>
        <div>error</div>
        <div className="iframe-logout-button" onClick={logout}>
          <div>sair</div>
        </div>
      </>
    )
  }
  return (
    <div className="content-container">
      {loading && (
        <div className="iframe-loading-container">
          <ReactLoading type="spin" color="#000" width="200px" height="200px" />
        </div>
      )}
			<div className="iframe-header">
        <div className="iframe-user-info-sub-header">
          <div className="iframe-title-container">
            <svg width="120" viewBox="0 0 218 44" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g>
                <path d="M17.4586 25.6157L11.835 24.2564C9.65488 23.6948 8.6565 23.3046 8.6565 21.8484C8.6565 20.1462 10.6657 19.4404 13.1158 19.4404C16.3412 19.5358 19.5069 20.3373 22.3911 21.7888L24.4597 14.9847C21.0439 13.6144 17.4039 12.8931 13.7252 12.8576C5.74805 12.8501 0.770996 16.7715 0.770996 22.4349C0.770996 26.9676 3.23103 29.6638 7.9876 30.8964L13.0761 32.1812C15.3058 32.7428 16.765 33.3044 16.765 34.4873C16.765 36.1895 15.068 36.7238 12.3057 36.7238C8.95347 36.5751 5.6801 35.656 2.73804 34.0375L1.28877 40.7048C4.82875 42.4059 8.70755 43.2785 12.6327 43.257C20.1714 43.257 24.5043 39.6686 24.5043 33.953C24.5216 29.6564 22.5447 26.9104 17.4586 25.6157Z" fill="#FFF"/>
                <path d="M109.825 19.4504C114.022 19.4504 115.771 21.8062 116.031 25.6157L122.968 24.0501C122.688 17.2461 118.278 12.5594 109.783 12.5594C107.727 12.4609 105.674 12.7954 103.755 13.5413C101.836 14.2872 100.093 15.4279 98.6405 16.89C97.1875 18.3522 96.0559 20.1033 95.3185 22.0307C94.5811 23.9581 94.2542 26.0191 94.3589 28.0809C94.3589 36.8829 99.2171 43.4408 110.214 43.4408C114.673 43.5035 119.028 42.0864 122.601 39.4101L119.584 33.2945C117.159 35.2298 114.197 36.3644 111.104 36.5424C109.933 36.6597 108.751 36.5167 107.642 36.1235C106.532 35.7303 105.523 35.0965 104.686 34.2674C103.849 33.4383 103.204 32.4342 102.798 31.3267C102.392 30.2191 102.234 29.0354 102.336 27.8597C102.233 26.805 102.349 25.7403 102.677 24.7328C103.005 23.7254 103.537 22.7972 104.241 22.0068C104.945 21.2165 105.805 20.5812 106.766 20.1412C107.727 19.7011 108.769 19.4659 109.825 19.4504V19.4504Z" fill="#FFF"/>
                <path d="M154.765 13.3446H146.939C145.242 22.82 140.572 24.2787 134.084 24.2787V2.85773H126.268V42.663H134.086V31.2318C136.055 31.2303 138.019 31.0422 139.953 30.6702L147.11 42.6704H156.301L146.83 28.041C151.136 25.2255 153.757 20.4642 154.765 13.3446Z" fill="#FFF"/>
                <path d="M167.033 13.3447H159.207V42.668H167.033V13.3447Z" fill="#FFF"/>
                <path d="M163.176 1.18286C160.451 1.18286 158.597 2.17687 158.597 5.0446C158.597 7.91233 160.446 8.97344 163.176 8.97344C165.906 8.97344 167.665 7.9173 167.665 5.0446C167.665 2.1719 165.839 1.18286 163.176 1.18286Z" fill="#FFF"/>
                <path d="M44.2095 13.2353C38.6612 12.3483 32.9853 13.6329 28.3542 16.8237V42.668H36.1902V21.1352C38.548 20.5309 40.9941 20.3525 43.4142 20.6084C45.2723 20.8221 48.369 21.918 48.369 25.1088V42.663H55.9993V25.0988C56.0092 18.7322 51.3741 14.197 44.2095 13.2353Z" fill="#FFF"/>
                <path d="M188.554 13.2353C183.064 12.4648 177.479 13.6445 172.766 16.5702H172.706V42.663H180.532V21.3986C182.891 20.7979 185.336 20.6196 187.756 20.8718C189.604 21.0855 192.711 21.918 192.711 25.1237V42.6804H200.341V25.0988C200.356 18.7197 195.751 14.0429 188.554 13.2353Z" fill="#FFF"/>
                <path d="M82.8467 15.6582C79.9894 13.5532 76.4706 12.5534 72.9372 12.8426C64.9922 13.1235 59.6089 19.1695 59.6089 28.0833C59.6089 36.9972 64.9922 43.1625 72.9372 43.1625C76.5569 43.1823 80.067 41.9174 82.8467 39.5915V42.663H90.6728V13.3397H82.8467V15.6582ZM82.8467 32.1911C81.9906 33.4798 80.8307 34.5367 79.4698 35.2679C78.109 35.9992 76.5893 36.3824 75.0454 36.3834C73.9776 36.4063 72.9169 36.2029 71.9329 35.7863C70.9489 35.3696 70.0636 34.7493 69.3351 33.9657C68.6066 33.1822 68.0513 32.2532 67.7055 31.2395C67.3597 30.2258 67.2312 29.1503 67.3284 28.0833C67.2311 27.0114 67.3588 25.9309 67.7033 24.9116C68.0478 23.8922 68.6014 22.9565 69.3285 22.1649C70.0556 21.3733 70.94 20.7433 71.9246 20.3156C72.9093 19.8878 73.9724 19.6718 75.0454 19.6815C76.6622 19.7296 78.237 20.2087 79.6081 21.0695C80.9791 21.9303 82.097 23.1418 82.8467 24.5795V32.1911Z" fill="#FFF"/>
                <path d="M218.003 8.85417C218.003 7.10298 217.485 5.39112 216.515 3.93506C215.545 2.479 214.167 1.34414 212.554 0.673987C210.941 0.00383637 209.166 -0.171506 207.454 0.170134C205.742 0.511774 204.169 1.35505 202.934 2.59333C201.7 3.83161 200.859 5.40927 200.518 7.12681C200.178 8.84435 200.353 10.6246 201.021 12.2425C201.689 13.8604 202.82 15.2432 204.272 16.2161C205.723 17.1891 207.43 17.7083 209.176 17.7083V21.9602C209.176 21.9602 217.128 17.01 217.926 9.95007C217.972 9.58649 217.997 9.22062 218.003 8.85417V8.85417Z" fill="#FFF"/>
              </g>
            </svg>

            <p className="iframe-title-big-bar">|</p>
            <p className="iframe-title-big-name">Portal</p>
          </div>
          <p className="iframe-user-email">{'>'} <span>{user?.email || 'not found'}</span></p>
          <div className="iframe-buttons-sub-container">
            <div className="iframe-logout-button" onClick={logout}>
              sair
            </div>
            {availableUrls?.length > 1 && (
              <div className="iframe-logout-button" onClick={() => {
                setChosenUrl(null);
              }}>
                Voltar
              </div>
            )}
          </div>
        </div>
			</div>
      <iframe
        style={{ width: '100vw', height: '100vh' }}
        id="iframe"
        title="Portal"
        onLoad={() => setLoading(false)}
        src={url}
      ></iframe>
    </div>
  );
}

export default Iframe;
