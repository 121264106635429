import { getApps, initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword, User } from "firebase/auth";
import {
  getFirestore,
  collection,
  getDocs,
  query,
  where,
  doc,
  getDoc,
} from 'firebase/firestore';

import { useCallback, useEffect, useMemo, useState } from "react";
import { userDataType } from "../types";

const useFirebase = () 
: {
	authenticateWithEmailAndPassword: (email: string, password: string) => Promise<any>;
	logout: () => void;
	isUserLoggedIn: boolean;
	loading: boolean;
  availableUrls: any[];
	user: userDataType | null;
} => {
  const [user, setUser] = useState<userDataType | null>(null);
	const [credential, setCredential] = useState<User | null>(null);
	const [userLoading, setUserLoading] = useState<boolean>(true);
  const [availableUrls, setAvailableUrls] = useState<any[]>([]);
	const [initializationLoading, setInitializationLoading] = useState<boolean>(false);

	const onAuthStateChanged = useCallback( async (cred: User | null) => {
    if (!cred) {
      setUserLoading(false);
    }

    try {
      setUserLoading(true);
      const user = await getUserInfo(cred);
      setCredential(cred);
      setUser(user);

      await getAvailableUrls(user);
      setUserLoading(false);
    } catch {
      setUserLoading(false);
      setUser(null);
    }
  }, [])

  useEffect(() => {
    const initialization = async () => {
      setInitializationLoading(true);
      await initializeFirebaseApp(onAuthStateChanged);
      setInitializationLoading(false);
    };

    initialization();
  }, [onAuthStateChanged]);

	async function initializeFirebaseApp(
		onAuthStateChanged: (user: User | null) => void,
	) {
		if (getApps().length) return;
		initializeApp({
		  apiKey: "AIzaSyC6Lrh7rtmxP-CSfNRmF0BBjujnRmdFOxI",
		  authDomain: "snackin-portal.firebaseapp.com",
		  projectId: "snackin-portal",
		  storageBucket: "snackin-portal.appspot.com",
		  messagingSenderId: "632082969826",
		  appId: "1:632082969826:web:b49ca0ea6b9390e609dabb",
		  measurementId: "G-FL0695HT0Y"
		});
		const auth = getAuth();
		auth.onAuthStateChanged(onAuthStateChanged);
	};

	const authenticateWithEmailAndPassword = (email: string, password: string): Promise<any> => {
		if (!getApps().length) return Promise.reject('Firebase app not initialized');

	  const auth = getAuth();
	  return signInWithEmailAndPassword(auth, email, password);
	}

  const isUserLoggedIn = useMemo(() => !!credential, [credential]);

	const logout: () => void = () => {
	  const auth = getAuth();
    setAvailableUrls([]);
    setCredential(null);
    setUser(null);
	  return auth.signOut();
	}

  const getAvailableUrls = async (
    user: userDataType | null,
  ):Promise<void> => {
    if (!getApps().length || !user) return Promise.reject('Firebase app not initialized');

    const db = getFirestore();
    const qr = query(
      collection(db, 'websites'),
      where('roles', 'array-contains-any', user.roles),
    );

    try {
      const urlsSnapshot = await getDocs(qr);
      const urls: any[] = [];
      urlsSnapshot.forEach((doc) => {
        urls.push(doc.data());
      });

      setAvailableUrls(urls);
    } catch (err) {
      setAvailableUrls([]);
    }
  }

  const getUserInfo = async (
    user: User | null,
  ): Promise<userDataType | null> => {
    if (!getApps().length || !user) return Promise.reject('Firebase app not initialized');
    const db = getFirestore();

    if (user) {
      try {
        const userDocRef = doc(db, 'users', user.uid);
        const userDataFromExtra = (
          await getDoc(userDocRef)
        ).data();
        const organizationRef = userDataFromExtra?.organizationRef;

        if (organizationRef) {
          const orgData: any = (
            await getDoc(organizationRef)
          ).data();

          if (orgData) {
            userDataFromExtra.roles = [
              ...userDataFromExtra.roles,
              ...orgData.roles,
            ];
          }
        }
        const userData: userDataType = {
          name: user?.displayName,
          id: user?.uid,
          email: user?.email,
          emailVerified: user?.emailVerified,
          roles: userDataFromExtra?.roles || [],
          organizationRef: userDataFromExtra?.organizationRef,
        };
        return userData;
      } catch (err) {
        return null;
      }
    }
    return null;
  };

	return useMemo(
    () => ({
			authenticateWithEmailAndPassword,
      getAvailableUrls,
			logout,
      isUserLoggedIn,
      availableUrls,
      loading: initializationLoading || userLoading,
      user,
    }),
    [isUserLoggedIn, user, userLoading, initializationLoading, availableUrls],
  );
}

export default useFirebase;
