import './index.css';

function SelectStore( { setChosenUrl, availableUrls }: any ): React.ReactElement {
  const handleSelection = (url: string) => {
    setChosenUrl(url);
  }

  return (
    <div className="select-screen">
			<div className="select-box">
				<div className="select-input-form">
          {availableUrls.map(({url, name}: any) => <div className="selection-option" onClick={() => handleSelection(url)}>
            {name}
          </div>)}
				</div>
			</div>
    </div>
  );
}

export default SelectStore;
